import React from 'react';
import './styles.scss';



function TheLoader() {
  return (
    <>
      <span className="loader"></span>
    </>
  );
}

export default TheLoader;
